<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-3">
              <CHeaderNav>
                <CHeaderNavItem class="px-3">
                  <button
                    class="c-header-nav-btn"
                    @click="openSidebarEvent('logisticInventoryFilter')"
                  >
                    <CIcon v-if="!loading" size="lg" name="cil-filter" class="mr-2"/>
                  </button>
                </CHeaderNavItem>
              </CHeaderNav>
            </div>
            <div class="col-9 text-right">
              <CButton color="success" square size="sm" 
                @click="openModalEvent('add', 'logisticInventoryForm', null, 'Yeni Talep Oluşturma Ekranı')"
              >
                Koli Id Tanımla
              </CButton>&nbsp;&nbsp;
              <CButton color="primary" square size="sm" @click="getExcel" >
                Excel'e Aktar
              </CButton>
            </div>
          </div>
        </CCardHeader>

        <CCardBody>
          <CDataTable
            :items="logisticInventories"
            :fields="fields"
            :items-per-page="10"
            :active-page="1"
            :loading="loading"
            pagination
            hover
            clickable-rows
            @row-clicked="rowClicked"
            v-on:refresh="filterLogisticInventories"
          >
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <FormModal 
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :operationEvent="logisticInventoryCrud"
      :actionType="actionType"
      :module="module"
      :form="form"
      :data="modalProps"
    />
    <FilterSidebar 
      :show="openSidebar" 
      :openSidebarEvent="openSidebarEvent"
      :operationEvent="filterLogisticInventories"
      :module="module"
      :form="filterForm"
      :data="filterParams"
      :loading="loading"
    />
  </CRow>
</template>

<script>
import FilterSidebar from '../components/filterSidebar'
import FormModal from '../components/formModal'
import { prepareFilterParams } from "@/helpers/custom"
import moment from "moment";

export default {
  name: 'LogisticInventory',
  components: { FilterSidebar, FormModal },
  data() {
    return {
      modalTitle: '',
      actionType: '',
      module: 'inventory',
      form: 'logisticInventoryForm',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      lastItem: {},
      openSidebar: false,
      filterForm: 'logisticInventoryFilter',
      filterParams: {},
      activePage: 1,
      fields: [
        {key: '0', label: 'Id',_style: 'font-size:12px'},
        {key: '1', label: 'SKU Id',_style: 'font-size:12px'},
        {key: '2', label: 'SKU Kodu',_style: 'font-size:12px'},
        {key: '3', label: 'Ürün Adı',_style: 'font-size:12px'},
        {key: '4', label: 'Ürün Tipi Adı',_style: 'font-size:12px'},
        {key: '5', label: 'Adet',_style: 'font-size:12px'},
        {key: '6', label: 'Ürün Kodu',_style: 'font-size:12px'},
        {key: '7', label: 'Koli Kodu',_style: 'font-size:12px'},
        {key: '8', label: 'Açıklama',_style: 'font-size:12px'},
      ],
      filters: [{
        type: 'string',
        dataIndex: 'skuCode',
        field: 'skuCode',
      }, {
        type: 'string',
        dataIndex: 'productName',
        field: 'productName'
      }, {
        type: 'string',
        dataIndex: 'productTypeName',
        field: 'productTypeName'
      }, {
        type: 'string',
        dataIndex: 'parcelCode',
        field: 'parcelCode'
      }, {
        type: 'string',
        dataIndex: 'logisticsCode',
        field: 'logisticsCode'

      }]
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    },
  },
  computed: {
    reloadParams () {
      return [
        this.activePage
      ]
    },
    loading(){
      return this.$store.getters.inventoryLoading
    },
    logisticInventories(){
      return this.$store.getters.logisticInventories
    }
  },
  methods: {
    openModalEvent(actionType, form, data, title, desc){
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },

    openSidebarEvent(form){
      this.openSidebar = !this.openSidebar
      this.filterForm = form
    },

    rowClicked(item, index) {
      this.lastItem = item
    },

    async filterLogisticInventories(params) {
      this.filterParams = params
      let formData = prepareFilterParams(params, this.filters)
      await this.$store.dispatch('logisticInventory_list', formData)

      if(this.openSidebar){
        await this.openSidebarEvent()
      }
    },

    async logisticInventoryCrud(item, action) {
      let params = {
        "logisticsInventoryTransferId": item.logisticsInventoryTransferId,
        "logisticsCodes": item.logisticsCodes
      }
      await this.$store.dispatch('logisticInventory_action', params )
      if(this.$store.getters.inventoryStatus.success){
        this.filterLogisticInventories(this.filterParams)
        this.openModalEvent()
      }
    },

    async getExcel() {
      let params = {
        responseType: 'blob'
      }
      await this.$store.dispatch('logisticInventory_excel', params);
      if(this.$store.getters.inventoryStatus){
        const url = window.URL.createObjectURL(new Blob([this.$store.getters.inventoryStatus]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'LogisticInventory_' + moment(new Date).format('DD/MM/YYYY HH:mm:ss') + '.xls');
        document.body.appendChild(link);
        link.click();
      }
    },
  },
  mounted() {
    this.filterLogisticInventories(this.filterParams);
  }
}
</script>
<style>
input,
select {
  padding: 0.45em 0.5em;
  font-size: 100%;
  border: 1px solid #ccc;
  width: 100%;
}
</style>
